import * as React from 'react'
import { useEffect, useState } from 'react'
import ApiService from '../../../services/api.service'
import { useParams } from 'react-router-dom'
import useAlert from '../../Alerting/useAlert'
import { ProjectContact } from './ProjectContact'
import Loading from '../../../utils/Loading'
import Grid from '@mui/material/Grid'
import { Square } from '../../Shared/Layouts/Square'
import { SquareTitle } from '../../Shared/Layouts/SquareTitle'
import { ProjectInfoSquare } from './Squares/ProjectInfoSquare'
import MyProjectContactForm from './MyProjectContactForm'
import UploadFilesModal from '../../Upload/DropZoneArea/UploadAttachmentsComponent'
import ListProjectUploads from '../../Upload/List/ListProjectUploads'
import { Alert, Box, Container } from '@mui/material'
import { Authority } from '../../../services/api/models/authority'
import Button from '@mui/material/Button/Button'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import DashboardV4 from '../../Dashboard/V3/DashboardV4'
import BreadcrumbsBackButton from '../../Shared/BreadcrumbsBackButton'
import FormControl from '@mui/material/FormControl'
import Stack from '@mui/material/Stack'
import AutocompleteField from '../../Integrations/Salesforce/AutocompleteField'

const ELEVATION = 3

function EditProject() {
    const { id } = useParams()
    const { setAlert } = useAlert()
    const [project, setProject] = React.useState<any | undefined>(undefined)
    const [contacts, setContacts] = React.useState<any[] | undefined>(undefined)
    const [accounts, setAccounts] = React.useState<any[] | undefined>(undefined)
    const [accountsResponse, setAccountsResponse] = React.useState<any[] | undefined>(undefined)
    const [contactsResponse, setContactsResponse] = React.useState<any[] | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<any | null>(null)
    const [generalContractorContact, setGeneralContractorContact] = React.useState<ProjectContact>({
        name: '',
        email: '',
        phone: '',
        contact_type: 'general_contractor',
        project_id: id || '',
    })
    const [architectContact, setArchitectContact] = React.useState<ProjectContact>({
        name: '',
        email: '',
        phone: '',
        contact_type: 'architect',
        project_id: id || '',
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const load = async function () {
        if (isLoading) {
            return
        }
        setIsLoading(true)
        ApiService.getProjectById(id || '')
            .then((response) => {
                if (response.status === 200 && response.data !== null) {
                    setProject(response.data)
                    response?.data?.contacts.forEach((c: any) => {
                        if (c['contact_type'] === 'general_contractor') {
                            setGeneralContractorContact(c)
                        }
                    })
                    response['data']['contacts'].forEach((c: any) => {
                        if (c['contact_type'] === 'architect') {
                            setArchitectContact(c)
                        }
                    })
                    ApiService.getSalesforceObjects('General Contractor Contact').then((res) => {
                        if (res.data && res.data.records) {
                            const cts = res.data.records.map((item: any) => {
                                return {
                                    id: item['Id'],
                                    name: item['Name'],
                                }
                            })
                            setContactsResponse(res.data.records)
                            setContacts(cts)
                        }
                    })
                    ApiService.getSalesforceObjects('General Contractor Account').then((res) => {
                        if (res.data && res.data.records) {
                            const cts = res.data.records.map((item: any) => {
                                return {
                                    id: item['Id'],
                                    name: item['Name'],
                                }
                            })
                            setAccountsResponse(res.data.records)
                            setAccounts(cts)
                        }
                    })
                }

                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                setProject(null)
                setError('Could not find project!')
            })
        // let response: any = {};
        // try {
        //
        //     response = await
        // } catch (e) {
        //
        // }
    }

    useEffect(() => {
        load().then(console.log)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box>
            {project && project?.name && (
                <>
                    <BreadcrumbsBackButton
                        currentPageText={project.name}
                        linkText={'Projects'}
                        navigateTo={'/projects'}
                    />
                </>
            )}
            {error !== null ? (
                <>
                    <Container sx={{ mt: 2 }}>
                        <Alert severity={'error'} variant="standard">
                            {error}
                        </Alert>
                    </Container>
                </>
            ) : (
                <></>
            )}
            {isLoading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    {error === null ? (
                        <>
                            <Box
                                style={{
                                    flex: 1,
                                    overflow: 'hidden',
                                    paddingTop: '20px',
                                    paddingBottom: '100px',
                                    margin: '20px',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Square elevation={ELEVATION}>
                                            <SquareTitle>Project Information</SquareTitle>
                                            {project ? (
                                                <ProjectInfoSquare
                                                    project={project}
                                                ></ProjectInfoSquare>
                                            ) : (
                                                <>
                                                    <Loading></Loading>
                                                </>
                                            )}
                                        </Square>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Square elevation={ELEVATION}>
                                            <SquareTitle>Architect</SquareTitle>
                                            {architectContact && project && (
                                                <MyProjectContactForm contact={architectContact} />
                                            )}
                                            <FormControl fullWidth margin="normal">
                                                <Stack direction={'column'} spacing={3}>
                                                    {accounts && (
                                                        <>
                                                            <AutocompleteField
                                                                key="Account"
                                                                options={accounts}
                                                                value={null}
                                                                onChange={(event, value) => {
                                                                    const salesforceContact =
                                                                        accountsResponse?.filter(
                                                                            (item) => {
                                                                                return (
                                                                                    item['Id'] ===
                                                                                    value?.id
                                                                                )
                                                                            },
                                                                        )[0]
                                                                    const projectContact = {
                                                                        name: salesforceContact[
                                                                            'Name'
                                                                        ],
                                                                        email: salesforceContact[
                                                                            'Email'
                                                                        ],
                                                                        phone: salesforceContact[
                                                                            'MobilePhone'
                                                                        ],
                                                                        contact_type: 'architect',
                                                                        project_id: project?.id,
                                                                    }

                                                                    ApiService.addProjectContact(
                                                                        project?.id || '',
                                                                        projectContact,
                                                                    ).then((res) => {
                                                                        console.log(res)
                                                                        load()
                                                                        setArchitectContact(
                                                                            projectContact,
                                                                        )
                                                                    })
                                                                }}
                                                                label={'Salesforce Account'}
                                                            />
                                                        </>
                                                    )}
                                                </Stack>
                                            </FormControl>
                                        </Square>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Square elevation={ELEVATION}>
                                            <SquareTitle>General Contractor</SquareTitle>
                                            {generalContractorContact && project && (
                                                <MyProjectContactForm
                                                    contact={generalContractorContact}
                                                />
                                            )}
                                            <FormControl fullWidth margin="normal">
                                                <Stack direction={'column'} spacing={3}>
                                                    {contacts && (
                                                        <>
                                                            <AutocompleteField
                                                                key="Contact"
                                                                options={contacts}
                                                                value={null}
                                                                onChange={(event, value) => {
                                                                    const salesforceContact =
                                                                        contactsResponse?.filter(
                                                                            (item) => {
                                                                                return (
                                                                                    item['Id'] ===
                                                                                    value?.id
                                                                                )
                                                                            },
                                                                        )[0]
                                                                    const projectContact = {
                                                                        name: salesforceContact[
                                                                            'Name'
                                                                        ],
                                                                        email: salesforceContact[
                                                                            'Email'
                                                                        ],
                                                                        phone: salesforceContact[
                                                                            'MobilePhone'
                                                                        ],
                                                                        contact_type:
                                                                            'general_contractor',
                                                                        project_id: project?.id,
                                                                    }

                                                                    ApiService.addProjectContact(
                                                                        project?.id || '',
                                                                        projectContact,
                                                                    ).then((res) => {
                                                                        console.log(res)
                                                                        load()
                                                                        setGeneralContractorContact(
                                                                            projectContact,
                                                                        )
                                                                    })
                                                                }}
                                                                label={'Salesforce Contact'}
                                                            />
                                                        </>
                                                    )}
                                                </Stack>
                                            </FormControl>
                                        </Square>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Square elevation={ELEVATION}>
                                            <SquareTitle>Upload</SquareTitle>
                                            {project ? (
                                                <>
                                                    <UploadFilesModal
                                                        projectId={project.id}
                                                    ></UploadFilesModal>
                                                    <ListProjectUploads
                                                        projectId={project.id}
                                                        uploads={project.uploads}
                                                        project={project}
                                                    ></ListProjectUploads>
                                                </>
                                            ) : (
                                                <>
                                                    <Loading></Loading>
                                                </>
                                            )}
                                        </Square>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Square elevation={ELEVATION}>
                                            <SquareTitle>Search Results</SquareTitle>
                                            {project ? (
                                                <>
                                                    <DashboardV4 projectId={project.id} />
                                                </>
                                            ) : (
                                                <>
                                                    <Loading></Loading>
                                                </>
                                            )}
                                        </Square>
                                    </Grid>
                                    <Grid item xs={0} sm={2}></Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        sx={{
                                            marginTop: 10,
                                            minHeight: 500,
                                        }}
                                    >
                                        <Square elevation={ELEVATION}>
                                            <SquareTitle>Manage</SquareTitle>
                                            {project ? (
                                                <>
                                                    {ApiService.isInRoles([Authority.Admin]) && (
                                                        <Box
                                                            style={{
                                                                marginBottom: 5,
                                                                maxWidth: 250,
                                                            }}
                                                            mt={2}
                                                        >
                                                            <Button
                                                                // size="small"
                                                                variant={'contained'}
                                                                color={'primary'}
                                                                fullWidth
                                                                onClick={function () {
                                                                    ApiService.processProjectById(
                                                                        id || '',
                                                                    ).then((res) => {
                                                                        setAlert(
                                                                            `processing uploads ${id}`,
                                                                            'success',
                                                                        )
                                                                    })
                                                                }}
                                                            >
                                                                Process Uploads
                                                            </Button>
                                                        </Box>
                                                    )}
                                                    {ApiService.isInRoles([
                                                        Authority.OrgAdmin,
                                                        Authority.Admin,
                                                    ]) && (
                                                        <Box m={2}>
                                                            <Alert severity={'warning'}>
                                                                <b>Warning:</b> this action cannot
                                                                be undone
                                                            </Alert>
                                                            <Button
                                                                // size="small"
                                                                variant={'contained'}
                                                                color={'error'}
                                                                fullWidth
                                                                sx={{
                                                                    maxWidth: 200,
                                                                    marginTop: 1,
                                                                    marginBottom: 1,
                                                                }}
                                                                onClick={function () {
                                                                    ApiService.deleteProjectById(
                                                                        id || '',
                                                                    ).then((res) => {
                                                                        setAlert(
                                                                            `deleted project ${id}`,
                                                                            'success',
                                                                        )
                                                                    })
                                                                }}
                                                            >
                                                                Delete Project
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Loading></Loading>
                                                </>
                                            )}
                                        </Square>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </Box>
    )
}

export default withAuthenticationRequired(EditProject)
