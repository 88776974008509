import * as React from 'react'
import { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import ApiService from '../../../services/api.service'
import { GridColDef, GridToolbar } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Button, Container, Link } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useNavigate } from 'react-router-dom'

// https://mui.com/material-ui/react-table/#data-table
export default function ListUploads() {
    const navigate = useNavigate() // Hook for programmatic navigation

    const columns: GridColDef[] = [
        {
            field: 'project',
            headerName: 'Project',
            flex: 1,
            renderCell: (params) => (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => navigate(`/projects/${params.row.project.id}/edit`)}
                >
                    {params.row.project.name}
                </Link>
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'date_updated',
            headerName: 'Date Updated',
            flex: 1,
        },
        {
            field: 'size_in_mb',
            headerName: 'Size',
            flex: 1,
            valueGetter: (value: any, row) => {
                try {
                    return Math.round(row.size_in_mb * 100) / 100
                } catch (e) {}
                return ``
            },
        },
        {
            field: 'organization',
            headerName: 'Organization',
            flex: 1,
            valueGetter: (value: any, row) => {
                try {
                    return row.organization.name
                } catch (e) {}
                return ``
            },
        },
    ]

    const [rows, setRows] = React.useState<any[]>([])

    useEffect(() => {
        async function load() {
            const result = await ApiService.getUploads()
            if (result.status === 200) {
                const rowItems = result.data
                setRows(rowItems)
                console.log(rowItems)
            }
        }

        load()
    }, [])

    return (
        <Container sx={{ minHeight: 250 }} maxWidth={'xl'}>
            <Stack spacing={2} mb={2}>
                <Button
                    variant={'contained'}
                    onClick={async () => {
                        const res = await ApiService.processAllSearchFilters()
                        console.log(res.data)
                    }}
                >
                    Process Filters
                </Button>
            </Stack>
            {rows.length > 0 && (
                <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 4 }}>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        density={'compact'}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 100 },
                            },
                        }}
                        pageSizeOptions={[100, 250, 500]}
                    />
                </Paper>
            )}
        </Container>
    )
}
