import * as React from 'react'
import { useEffect, useState } from 'react'
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid'
import { DataGridPro, GridFooter, GridFooterContainer } from '@mui/x-data-grid-pro'
import { Box, Button } from '@mui/material'
import ApiService from '../../../services/api.service'
import { Projects } from '../../../services/api/models/projects'
import ButtonGroup from '@mui/material/ButtonGroup'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import useAlert from '../../Alerting/useAlert'
import { toLocalDate } from '../ToLocalDate'
import { withAuthenticationRequired } from '@auth0/auth0-react'

function RenderViewById(params: GridRenderCellParams<any, string>) {
    if (params.value === undefined || params.value.toString() === '') {
        return <></>
    }

    function onClick(ev: any, id: string) {
        window.location.pathname = `/projects/${id}/edit`
    }

    return (
        <Button
            key={`${params.value || new Date().toString()}-view-button`}
            size="small"
            color={'primary'}
            variant={'contained'}
            onClick={(ev: any) => {
                onClick(ev, params.value || '')
            }}
        >
            View
        </Button>
    )
}

// https://mui.com/material-ui/react-table/#data-table
function ProjectsList() {
    const [selectedIds, setSelectedIds] = useState<any[]>([])
    const { setAlert } = useAlert()
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: '',
            minWidth: 36,
            maxWidth: 120,
            flex: 1,
            sortable: false,
            renderCell: (params) => <RenderViewById {...params} />,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1.5,
            // minWidth: 250,
        },
        // {
        //     field: 'alternate_name',
        //     headerName: 'Alternate Name',
        //     flex: 1,
        //     // minWidth: 250,
        // },

        {
            field: 'bid_date',
            headerName: 'Bid Date',
            flex: 0.5,
            sortable: true,
            type: 'date',
            valueFormatter: (params: any) => {
                if (params === null || params === undefined) {
                    return ``
                }
                return new Date(params).toLocaleDateString()
            },
            // valueGetter: (params: any) => {
            //     if (params?.value === null || params?.value === undefined) {
            //         return ``
            //     }
            //     return new Date(params.value)
            // },
        },
        {
            field: 'dollar_amount',
            headerName: 'Dollar Amount',
            flex: 0.5,
            // sortable: true,
            type: 'number',
            // valueGetter: (params: any) => {
            //     if (params?.value === null || params?.value === undefined) {
            //         return 0
            //     }
            //     return parseFloat(params.value).toFixed(0)
            // },
            valueFormatter: (params: any) => {
                const nf = new Intl.NumberFormat()
                return `$${nf.format(params)}`
            },
        },
        {
            field: 'uploads',
            headerName: 'Uploads',
            flex: 0.5,
            sortable: true,
            type: 'number',
            valueGetter: (params: any) => {
                if (params === null) {
                    return 0
                }

                return params.length
            },
        },
        {
            field: 'created_by_user_email',
            headerName: 'Created by',
            flex: 1,
            sortable: true,
            type: 'string',
        },
        {
            field: 'date_created',
            headerName: 'Created At',
            flex: 1,
            sortable: true,
            type: 'date',
            valueFormatter: (params) => {
                return toLocalDate(params)
            },
            valueGetter: (params: any) => {
                return new Date(params)
            },
        },
    ]

    const [rows, setRows] = React.useState<Projects[]>([])
    const [cols] = React.useState<GridColDef[]>(columns)
    const [loading, setLoading] = useState<boolean>(false)

    async function load() {
        if (loading) {
            return
        }
        setLoading(true)
        const result = await ApiService.getProjects()
        if (result.status === 200) {
            const rowItems = result.data
            setRows(rowItems)
        }
        setLoading(false)
    }

    useEffect(() => {
        load().catch(console.error)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function handleBulkDelete() {
        setAlert(`deleting ${selectedIds.length} projects`, 'info')
        for (const selectedId of selectedIds) {
            await ApiService.deleteProjectById(selectedId)
            setAlert(
                `deleted project (${selectedIds.indexOf(selectedId) + 1}/${selectedIds.length}) ${selectedId}`,
                'success',
            )
        }
        load().catch(console.error)
    }

    function CustomFooter() {
        return (
            <GridFooterContainer>
                <Box
                    sx={{
                        // maxWidth: 800,
                        flex: 1,
                        overflow: 'hidden',
                        textAlign: 'left',
                        paddingLeft: '5px',
                        // overflow: 'hidden',
                        // display: 'flex',
                        // '& > *': {
                        //     m: 1,
                        // },
                    }}
                >
                    {selectedIds.length > 0 && (
                        <>
                            <ButtonGroup
                                orientation="horizontal"
                                aria-label="Vertical button group"
                                variant="text"
                            >
                                <Button
                                    key={`download-button`}
                                    size="small"
                                    color={'error'}
                                    fullWidth
                                    variant={'outlined'}
                                    onClick={handleBulkDelete}
                                    startIcon={
                                        <DeleteOutlinedIcon />
                                        // isDownloadingZipFiles ? (
                                        //     <CircularProgress size={12} />
                                        // ) : (
                                        // )
                                    }
                                    // disabled={isDownloadingZipFiles}
                                    // onClick={downloadPdfZipFiles}
                                >
                                    Delete Selected Projects
                                    {/*{isDownloadingZipFiles*/}
                                    {/*    ? `Downloading ${selectedRows.length} files`*/}
                                    {/*    : `Download zip (${selectedRows.length} files)`}*/}
                                </Button>
                            </ButtonGroup>
                        </>
                    )}
                </Box>
                <GridFooter
                    sx={{
                        border: 'none', // To delete double border.
                    }}
                ></GridFooter>
            </GridFooterContainer>
        )
    }

    return (
        <DataGridPro
            rows={rows}
            columns={cols}
            density={'compact'}
            disableRowSelectionOnClick={true}
            disableColumnReorder={true}
            checkboxSelection
            onRowSelectionModelChange={(ids) => setSelectedIds(ids)}
            slots={{
                toolbar: GridToolbar,
                footer: CustomFooter,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                },
            }}
            loading={rows.length === 0 && loading}
            initialState={{
                pagination: {
                    paginationModel: {
                        page: 0,
                        pageSize: 10,
                    },
                },
            }}
            pagination
            pageSizeOptions={[10, 25, 50, 250, 1000]}
        />
    )
}

export default withAuthenticationRequired(ProjectsList)
