import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { createTheme, styled, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ListAlt from '@mui/icons-material/ListAlt'
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined'
import CategoryOutlined from '@mui/icons-material/CategoryOutlined'
import Add from '@mui/icons-material/Add'

import ListItemText from '@mui/material/ListItemText'
import {
    AnalyticsOutlined,
    CurrencyExchangeOutlined,
    HomeOutlined,
    HomeRounded,
    LoginRounded,
    LogoutRounded,
    ManageAccountsRounded,
    SavedSearchOutlined,
    SearchOutlined,
    StarBorderOutlined,
    TableChart,
} from '@mui/icons-material'
import AppRouter from '../../AppRouter'
import { Avatar, Button, Switch } from '@mui/material'
import ApiService from '../../services/api.service'
import { useAuth0 } from '@auth0/auth0-react'
import Footer from '../Examples/landing-page/components/Footer'
import CssBaseline from '@mui/material/CssBaseline'
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded'
import AlertPopup from '../Alerting/AlertPopup'
import Collapse from '@mui/material/Collapse'
import { Authority } from '../../services/api/models/authority'
import Loading from '../../utils/Loading'
import { amber, deepPurple } from '@mui/material/colors'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    marginTop: 12,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))

export default function PersistentDrawerLeft() {
    const { user, isAuthenticated, getAccessTokenSilently, logout, isLoading, loginWithPopup } =
        useAuth0()

    const [mode, setMode] = useState<string>(ApiService.isDarkMode() ? 'dark' : 'light')

    const theme = useMemo(() => {
        return createTheme({
            palette: {
                mode: mode === 'dark' ? 'dark' : 'light',
                primary: {
                    main: deepPurple[600],
                },
                secondary: {
                    main: amber[800],
                },
                // primary: {
                //     main: '#635bff', // Stripe's primary blue color
                // },
                // secondary: {
                //     main: '#00d4ff', // A bright secondary color
                // },
                // background: {
                //     default: '#ffffff', // White background
                //     paper: '#f6f9fc', // Slightly off-white for paper elements
                // },
                // text: {
                //     primary: '#32325d', // Dark text color
                //     secondary: '#6b7c93', // Grey text color
                // },
            },
            typography: {
                fontFamily: '"Helvetica Neue", "Arial", sans-serif', // Stripe's font family
                h1: {
                    fontWeight: 500,
                    fontSize: '1.8rem',
                },
                h2: {
                    fontWeight: 500,
                    fontSize: '1.5rem',
                },
                body1: {
                    fontWeight: 400,
                    fontSize: '1rem',
                },
                // button: {
                //     textTransform: 'none', // No uppercase for buttons
                // },

                // button: {
                //     textTransform: 'none', // No uppercase for buttons
                // },
            },
            spacing: 4, // Reduce the default spacing (default is 8)
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: '6px', // Slightly rounded buttons
                            padding: '2px 10px', // Reduce padding
                        },
                    },
                },
                MuiListItemButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: '6px', // Slightly rounded buttons
                            padding: '4px 10px', // Reduce padding
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            padding: '0px', // Reduce padding for paper elements
                            boxShadow:
                                '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)', // Subtle shadow
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            margin: '4px 0', // Reduce margin for text fields
                        },
                    },
                    defaultProps: {
                        size: 'small',
                    },
                },
                // MuiContainer: {
                //     styleOverrides: {
                //         root: {
                //             padding: '12px', // Reduce padding for containers
                //         },
                //     },
                // },
            },
        })
    }, [mode])

    const [projectsTabOpen, setProjectsTabOpen] = React.useState(ApiService.isProjectsOpen())
    const [analyticsTabOpen, setAnalyticsTabOpen] = React.useState(ApiService.isAnalyticsOpen())
    const [adminTabOpen, setAdminTabOpen] = React.useState(ApiService.isAdminOpen())

    const handleClickProjectsTab = () => {
        const val = !projectsTabOpen
        setProjectsTabOpen(val)
        ApiService.setProjectsOpen(val)
    }
    const handleClickAnalyticsTab = () => {
        const val = !analyticsTabOpen
        setAnalyticsTabOpen(val)
        ApiService.setAnalyticsOpen(val)
    }
    const handleClickAdminTab = () => {
        const val = !adminTabOpen
        setAdminTabOpen(val)
        ApiService.setAdminOpen(val)
    }

    const [state, setState] = useState({
        showResult: false,
        apiMessage: '',
        apiMessages: [],
        error: '',
    })

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const logoutWithRedirect = () =>
        ApiService.logout().then((res) => {
            logout({
                logoutParams: {
                    returnTo: window.location.origin,
                },
            }).then((r) => {})
        })

    useEffect(() => {
        async function login() {
            try {
                let token = await getAccessTokenSilently({
                    detailedResponse: true,
                })
                ApiService.saveToken(token.access_token)
                await ApiService.getAccount()
            } catch (e) {
                ApiService.removeLocalStorage()
                const msg = (e || '').toString().toLowerCase()
                if (msg.indexOf('login required') !== -1) {
                    setState({
                        ...state,
                        error: 'login_required',
                    })
                }
                if (msg.indexOf('consent required') !== -1) {
                    setState({
                        ...state,
                        error: 'consent_required',
                    })
                }
            }
        }
        login().catch(console.error)

        // eslint-disable-next-line
    }, [])

    const [open, setOpen] = React.useState(ApiService.isSidebarOpen())
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [orgOpen, setOrgOpen] = React.useState(true)

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (path: string) => {
        setAnchorEl(null)
    }
    const handleNavigate = (path: string) => {
        setAnchorEl(null)
        window.location.href = path
    }

    const handleDrawerOpen = () => {
        setOpen(true)
        ApiService.setSidebarOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
        ApiService.setSidebarOpen(false)
    }

    // const handleOrgClick = () => {
    //     setOrgOpen(!orgOpen);
    // };

    let indentPaddingLeft = 8
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {/*<Typography variant="h6" component="div" sx={{flexGrow: 1}}>*/}
                        {/*    construct quick est*/}
                        {/*</Typography>*/}
                        <Box sx={{ flexGrow: 1, alignItems: 'right', textAlign: 'right' }}>
                            {!isAuthenticated && !isLoading && (
                                <Button color="inherit" onClick={() => loginWithPopup()}>
                                    Log in
                                </Button>
                            )}
                            {isAuthenticated ? (
                                <>
                                    <div>
                                        <IconButton
                                            size="small"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleMenu}
                                            color="inherit"
                                        >
                                            {/*<div className={'app-bar-username'}>{user?.email}</div>*/}
                                            <Avatar
                                                alt={user?.email}
                                                src={
                                                    ApiService.getAccountFromLocalStorage()?.user
                                                        ?.user_info?.picture
                                                }
                                                sx={
                                                    {
                                                        // background: 'white',
                                                    }
                                                }
                                            />
                                        </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem
                                                sx={{ minWidth: 200 }}
                                                onClick={function (ev: any) {
                                                    handleNavigate('/account')
                                                }}
                                            >
                                                Account
                                            </MenuItem>
                                            <MenuItem
                                                sx={{ minWidth: 200 }}
                                                onClick={function (ev: any) {
                                                    logoutWithRedirect()
                                                }}
                                            >
                                                Logout
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {!isLoading && (
                            <>
                                {isAuthenticated ? (
                                    <>
                                        <ListItem key={'Home'} disablePadding>
                                            <ListItemButton href={'/'}>
                                                <ListItemIcon>
                                                    <HomeOutlined></HomeOutlined>
                                                </ListItemIcon>
                                                <ListItemText primary={'Home'} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem key={'Dashboard'} disablePadding>
                                            <ListItemButton href={'/dashboard'}>
                                                <ListItemIcon>
                                                    <ViewQuiltRoundedIcon></ViewQuiltRoundedIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Dashboard'} />
                                            </ListItemButton>
                                        </ListItem>

                                        <ListItem key={'Search Filters'} disablePadding>
                                            <ListItemButton href={'/search-filters'}>
                                                <ListItemIcon>
                                                    <SavedSearchOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary={'Search Filters'} />
                                            </ListItemButton>
                                        </ListItem>

                                        <ListItem key={'All Search Results'} disablePadding>
                                            <ListItemButton href={'/project-search-results'}>
                                                <ListItemIcon>
                                                    <SavedSearchOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary={'Search Results'} />
                                            </ListItemButton>
                                        </ListItem>

                                        <ListItem disablePadding>
                                            <ListItemButton>
                                                <ListItemIcon onClick={handleClickProjectsTab}>
                                                    <TableChart />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="Projects"
                                                    onClick={() => {
                                                        window.location.pathname = '/projects'
                                                    }}
                                                />
                                                {projectsTabOpen ? (
                                                    <Box onClick={handleClickProjectsTab}>
                                                        <ExpandLess />
                                                    </Box>
                                                ) : (
                                                    <Box onClick={handleClickProjectsTab}>
                                                        <ExpandMore />
                                                    </Box>
                                                )}
                                            </ListItemButton>
                                        </ListItem>
                                        <Collapse in={projectsTabOpen} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton
                                                    sx={{ pl: indentPaddingLeft, width: '100%' }}
                                                    onClick={() => {
                                                        window.location.pathname = '/projects'
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <ListAlt />
                                                    </ListItemIcon>
                                                    <ListItemText primary="View All" />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ pl: indentPaddingLeft, width: '100%' }}
                                                    onClick={() => {
                                                        window.location.pathname = '/projects/add'
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <Add />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Create Project" />
                                                </ListItemButton>
                                            </List>
                                        </Collapse>

                                        <ListItem disablePadding>
                                            <ListItemButton>
                                                <ListItemIcon onClick={handleClickAnalyticsTab}>
                                                    <AnalyticsOutlined />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="Analytics"
                                                    onClick={() => {
                                                        window.location.pathname = '/analytics'
                                                    }}
                                                />
                                                {analyticsTabOpen ? (
                                                    <Box onClick={handleClickAnalyticsTab}>
                                                        <ExpandLess />
                                                    </Box>
                                                ) : (
                                                    <Box onClick={handleClickAnalyticsTab}>
                                                        <ExpandMore />
                                                    </Box>
                                                )}
                                            </ListItemButton>
                                        </ListItem>
                                        <Collapse
                                            in={analyticsTabOpen}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <List component="div" disablePadding>
                                                <ListItemButton
                                                    sx={{ pl: indentPaddingLeft, width: '100%' }}
                                                    onClick={() => {
                                                        window.location.pathname = '/analytics'
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <ListAlt />
                                                    </ListItemIcon>
                                                    <ListItemText primary="View All" />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ pl: indentPaddingLeft, width: '100%' }}
                                                    onClick={() => {
                                                        handleNavigate(
                                                            '/analytics/search-filter-category',
                                                        )
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <CategoryOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="By Category" />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ pl: indentPaddingLeft, width: '100%' }}
                                                    onClick={() => {
                                                        handleNavigate('/analytics/search-filter')
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <SearchOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="By Filter" />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ pl: indentPaddingLeft, width: '100%' }}
                                                    onClick={() => {
                                                        handleNavigate('/analytics/user-reviews')
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <AccountCircleOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="By User" />
                                                </ListItemButton>
                                            </List>
                                        </Collapse>

                                        {ApiService.isInRoles([
                                            Authority.OrgAdmin,
                                            Authority.Admin,
                                        ]) && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton>
                                                        <ListItemIcon onClick={handleClickAdminTab}>
                                                            <VerifiedUserRoundedIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Admin"
                                                            onClick={() => {
                                                                window.location.pathname = '/admin'
                                                            }}
                                                        />
                                                        {adminTabOpen ? (
                                                            <Box onClick={handleClickAdminTab}>
                                                                <ExpandLess />
                                                            </Box>
                                                        ) : (
                                                            <Box onClick={handleClickAdminTab}>
                                                                <ExpandMore />
                                                            </Box>
                                                        )}
                                                    </ListItemButton>
                                                </ListItem>
                                                <Collapse
                                                    in={adminTabOpen}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <List component="div" disablePadding>
                                                        <ListItemButton
                                                            sx={{ pl: 4, width: '100%' }}
                                                            onClick={() => {
                                                                window.location.pathname =
                                                                    '/admin/manage-users'
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <ListAlt />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Manage Users" />
                                                        </ListItemButton>
                                                        {ApiService.isInRoles([
                                                            Authority.Admin,
                                                        ]) && (
                                                            <ListItemButton
                                                                sx={{ pl: 4, width: '100%' }}
                                                                onClick={() => {
                                                                    window.location.pathname =
                                                                        '/admin/organizations'
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <ListAlt />
                                                                </ListItemIcon>
                                                                <ListItemText primary="Manage Orgs" />
                                                            </ListItemButton>
                                                        )}
                                                    </List>
                                                </Collapse>
                                            </>
                                        )}

                                        <Divider sx={{ mt: 1, mb: 2 }} />

                                        <ListItem key={'Account'} disablePadding>
                                            <ListItemButton href={'/account'}>
                                                <ListItemIcon>
                                                    <ManageAccountsRounded></ManageAccountsRounded>
                                                </ListItemIcon>
                                                <ListItemText primary={'Account'} />
                                            </ListItemButton>
                                        </ListItem>

                                        {/*<ListItemButton onClick={handleOrgClick}>*/}
                                        {/*    <ListItemIcon>*/}
                                        {/*        <ManageSearch></ManageSearch>*/}
                                        {/*    </ListItemIcon>*/}
                                        {/*    <ListItemText primary="Organization" />*/}
                                        {/*    {orgOpen ? <ExpandLess /> : <ExpandMore />}*/}
                                        {/*</ListItemButton>*/}
                                        {/*<Collapse in={orgOpen} timeout="auto" unmountOnExit>*/}
                                        {/*    <List component="div" disablePadding>*/}
                                        {/*        <ListItemButton sx={{ pl: 4 }}>*/}
                                        {/*            <ListItemIcon>*/}
                                        {/*                <StarBorder />*/}
                                        {/*            </ListItemIcon>*/}
                                        {/*            <ListItemText primary="Starred" />*/}
                                        {/*        </ListItemButton>*/}
                                        {/*    </List>*/}
                                        {/*</Collapse>*/}

                                        <Divider sx={{ mt: 1, mb: 2 }} />
                                        <List>
                                            <ListItem key={'Logout'} disablePadding>
                                                <ListItemButton href={'/logout'}>
                                                    <ListItemIcon>
                                                        <LogoutRounded></LogoutRounded>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Logout'} />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </>
                                ) : (
                                    <>
                                        <ListItem key={'Home'} disablePadding>
                                            <ListItemButton href={'/'}>
                                                <ListItemIcon>
                                                    <HomeRounded></HomeRounded>
                                                </ListItemIcon>
                                                <ListItemText primary={'Home'} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem key={'Pricing'} disablePadding>
                                            <ListItemButton href={'/pricing'}>
                                                <ListItemIcon>
                                                    <CurrencyExchangeOutlined></CurrencyExchangeOutlined>
                                                </ListItemIcon>
                                                <ListItemText primary={'Pricing'} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem key={'Features'} disablePadding>
                                            <ListItemButton href={'/features'}>
                                                <ListItemIcon>
                                                    <StarBorderOutlined></StarBorderOutlined>
                                                </ListItemIcon>
                                                <ListItemText primary={'Features'} />
                                            </ListItemButton>
                                        </ListItem>

                                        <ListItem key={'Login'} disablePadding>
                                            <ListItemButton href={'/login'}>
                                                <ListItemIcon>
                                                    <LoginRounded></LoginRounded>
                                                </ListItemIcon>
                                                <ListItemText primary={'Login'} />
                                            </ListItemButton>
                                        </ListItem>
                                    </>
                                )}
                            </>
                        )}
                    </List>

                    {!isLoading && (
                        <List>
                            <ListItem key={'Logout'} disablePadding>
                                <ListItemButton href={'/logout'}>
                                    Dark Mode
                                    <Switch
                                        onChange={() => {
                                            setMode(mode === 'light' ? 'dark' : 'light')
                                            ApiService.setIsDarkMode(mode === 'light')
                                        }}
                                        defaultChecked={mode === 'dark'}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    )}
                </Drawer>
                <Main open={open}>
                    <AlertPopup />
                    <DrawerHeader />
                    {isLoading ? <Loading></Loading> : <AppRouter></AppRouter>}
                    {/*{state.error === "login_required" && (*/}
                    {/*    <Box sx={{minHeight: 80}}>*/}
                    {/*        <Alert color="warning" onClick={handleLoginAgain}>*/}
                    {/*            You need to log in again. Click here to login.*/}
                    {/*        </Alert>*/}
                    {/*    </Box>*/}
                    {/*)}*/}
                    {/*{state.error === "consent_required" && (*/}
                    {/*    <Box sx={{minHeight: 80}}>*/}
                    {/*        <Alert color="warning" onClick={handleConsent}>*/}
                    {/*            You need to consent to get access to users api*/}
                    {/*        </Alert>*/}
                    {/*    </Box>*/}
                    {/*)}*/}
                    <Box
                        sx={{
                            flexGrow: 1,
                            mt: 20,
                            mb: 0,
                        }}
                    >
                        <Footer />
                    </Box>
                </Main>
            </Box>
        </ThemeProvider>
    )
}
