import * as React from 'react'
import { useEffect, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import { DeleteTwoTone } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Alert, Stack } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import ApiService from '../../../../services/api.service'
import { useNavigate, useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'

import Snackbar from '@mui/material/Snackbar'
import ButtonGroup from '@mui/material/ButtonGroup'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { scrollToTop } from '../../../Shared/scrollToTop'

export default function SearchFilterCreate() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [searchTermList, setSearchTermList] = React.useState<any[]>([])
    const [existingSearchFilterCategories, setExistingSearchFilterCategories] = React.useState<
        CategoryOptionType[]
    >([])
    const [currentTextValue, setCurrentTextValue] = useState('')
    const [currentTitle, setCurrentTitle] = useState('')
    const [errorText, setErrorText] = useState('')
    const [snackMessage, setSnackMessage] = useState('')
    const [snackOpen, setSnackOpen] = React.useState(false)
    const [inputValueCategory, setInputValueCategory] = React.useState<CategoryOptionType | null>(
        null,
    )
    const filter = createFilterOptions<CategoryOptionType>()

    const handleTextInputChange = (event: any) => {
        setCurrentTextValue(event.target.value)
    }

    async function load() {
        if (id !== undefined) {
            scrollToTop()
            const result = await ApiService.getSearchFilterById(id)
            setCurrentTitle(result.data['name'])
            setInputValueCategory({
                name: result.data['category'],
            })
            setSearchTermList(result.data.terms.map((item: any) => item['name']))
        }
        const searchFilterCategoryResponse = await ApiService.getAllSearchFilterCategories()
        const searchCategoryOptions: CategoryOptionType[] = []
        Array.from(searchFilterCategoryResponse.data.map((x: any) => x.toString())).forEach(
            (item) => {
                searchCategoryOptions.push({
                    name: item!.toString(),
                })
            },
        )
        setExistingSearchFilterCategories(searchCategoryOptions)
    }

    useEffect(() => {
        load()

        // eslint-disable-next-line
    }, [])
    const handleDelete = (value: number) => () => {
        const newValue = [...searchTermList].filter((x) => x !== value)
        setSearchTermList(newValue)
    }

    const addCurrentSearchTerm = function () {
        if (currentTextValue === null) {
            return
        }
        if (currentTextValue.toString().length === 0) {
            setErrorText(`Please enter a valid value`)
        }
        if (searchTermList.indexOf(currentTextValue) === -1) {
            setSearchTermList((searchTermList) => [...searchTermList, currentTextValue])
            setCurrentTextValue('')
        } else {
            setErrorText(`Already added ${currentTextValue}`)
        }
    }
    const onSearchTermSubmit = (ev: any) => {
        ev.preventDefault()
        addCurrentSearchTerm()
    }

    function navigateToSearchWithGroup() {
        navigate(`/search-filters/${id || ''}/search`)
    }

    function saveCurrent() {
        console.log({
            title: currentTitle,
            terms: searchTermList,
            inputValueCategory: inputValueCategory,
        })
        setSnackMessage(`Saved search filter ${currentTitle} in ${inputValueCategory?.name}`)
        ApiService.saveSearchGroup(
            currentTitle,
            searchTermList,
            inputValueCategory?.name || '',
        ).then((x) => {
            setSnackOpen(true)
            navigate(`/search-filters/${id || x.data['id']}/edit`)
        })
    }

    const onTitleSubmit = (ev: any) => {
        ev.preventDefault()
        saveCurrent()
    }

    const onChangeCapture = (ev: any) => {
        setErrorText('')
    }

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackOpen(false)
    }

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    )

    return (
        <Container sx={{ p: 2 }}>
            <div>
                <Snackbar
                    open={snackOpen}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}
                    action={action}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {snackMessage}
                    </Alert>
                </Snackbar>
            </div>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        margin="normal"
                        sx={{ pb: 2 }}
                        required
                        fullWidth
                        size={'small'}
                        id="title"
                        label="Title"
                        name="title"
                        // autoFocus={true}
                        onSubmit={onTitleSubmit}
                        value={currentTitle}
                        onChange={function (ev) {
                            setCurrentTitle(ev.target.value)
                        }}
                    />
                    <Autocomplete
                        value={inputValueCategory}
                        size={'small'}
                        sx={{ pb: 2 }}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                setInputValueCategory({
                                    name: newValue,
                                })
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setInputValueCategory({
                                    name: newValue.inputValue,
                                })
                            } else {
                                setInputValueCategory(newValue)
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params)

                            const { inputValue } = params
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                                (option) => inputValue.toLowerCase() === option.name.toLowerCase(),
                            )
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name: `Add "${inputValue}"`,
                                })
                                console.log(inputValue)
                            }

                            return filtered
                        }}
                        selectOnFocus
                        clearOnBlur
                        autoFocus={true}
                        handleHomeEndKeys
                        id="category-name"
                        options={existingSearchFilterCategories}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue
                            }
                            // Regular option
                            return option.name
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        freeSolo
                        renderInput={(params) => <TextField {...params} label="Category" />}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <ButtonGroup orientation={'vertical'}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onSubmit={onTitleSubmit}
                            onClick={onTitleSubmit}
                            sx={{
                                mb: 1,
                                mt: 2,
                                maxWidth: 250,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'left',
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            fullWidth
                            variant="outlined"
                            color={'secondary'}
                            onClick={navigateToSearchWithGroup}
                            sx={{
                                mb: 1,
                                mt: 2,
                                maxWidth: 250,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'left',
                            }}
                        >
                            Start Searching
                        </Button>
                    </ButtonGroup>
                </Grid>

                <Grid item xs={12} md={6}>
                    {currentTitle && currentTitle.length > 0 && (
                        <Box
                            component="form"
                            onSubmit={onSearchTermSubmit}
                            autoComplete="off"
                            onErrorCapture={function (ev) {
                                console.log('error capture', ev)
                            }}
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                        >
                            <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
                                <InputBase
                                    sx={{ pl: 1, flex: 1 }}
                                    fullWidth
                                    placeholder="Add a new search term here"
                                    inputProps={{ 'aria-label': 'Add a new search term here' }}
                                    value={currentTextValue}
                                    onChange={handleTextInputChange}
                                    // autoFocus={false}
                                    onSubmit={onSearchTermSubmit}
                                    onChangeCapture={onChangeCapture}
                                />
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton
                                    color="primary"
                                    sx={{ p: '10px' }}
                                    aria-label="directions"
                                    onClick={addCurrentSearchTerm}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Paper>
                        </Box>
                    )}
                    {errorText.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                maxWidth: 400,
                                paddingTop: '10px',
                            }}
                        >
                            <>
                                <Stack sx={{ width: '100%' }} spacing={1}>
                                    <Alert severity="error">{errorText}</Alert>
                                </Stack>
                            </>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {currentTitle && currentTitle.length > 0 && (
                        <List
                            sx={{
                                maxWidth: '300px',
                                bgcolor: 'background.paper',
                                paddingLeft: 0,
                                marginLeft: 0,
                            }}
                        >
                            {searchTermList.sort().map((term) => {
                                const labelId = `checkbox-list-label-${term}`
                                return (
                                    <ListItem key={term} disablePadding>
                                        <ListItemButton dense>
                                            <ListItemText id={labelId} primary={term} />
                                            <ListItemIcon>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="comments"
                                                    onClick={handleDelete(term)}
                                                >
                                                    <DeleteTwoTone />
                                                </IconButton>
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}

interface CategoryOptionType {
    inputValue?: string
    name: string
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
