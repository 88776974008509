/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import ApiService from '../../../services/api.service'
import { GridColDef, GridFilterModel, GridToolbar } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box, Button, CircularProgress, Container, Link, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Config } from '../../../config/config'

export default function ListProjectSearchResults() {
    const navigate = useNavigate() // Hook for programmatic navigation
    const [searchParams, setSearchParams] = useSearchParams() // Hook to manage URL search params
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({ items: [] })

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 1,
        },
        {
            field: 'project',
            headerName: 'Project',
            flex: 1,
            renderCell: (params) => (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => navigate(`/projects/${params.row.project_id}/edit`)}
                >
                    {params.row.project_name}
                </Link>
            ),
        },
        {
            field: 'search_filter',
            headerName: 'Search Filter',
            flex: 1,
            renderCell: (params) => (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => navigate(`/search-filters/${params.row.search_filter_id}/edit`)}
                >
                    {params.row.search_filter_name}
                </Link>
            ),
        },
        {
            field: 'search_filter_term_name',
            headerName: 'term',
            flex: 1,
            filterable: true,
        },
        {
            field: 'upload',
            headerName: 'Upload',
            flex: 1,
            renderCell: (params) => (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        const url = ApiService.viewUploadUrl(params.row.upload_id)
                        window.open(url, '_blank')
                    }}
                >
                    {params.row.upload_name}
                </Link>
            ),
            valueGetter: (params: any, row: any) => row.upload_name,
        },
        {
            field: 'page_number',
            headerName: 'Page',
            flex: 1,
            renderCell: (params) => (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        const url = ApiService.viewUploadUrl(
                            params.row.upload_id,
                            params.row.page_number,
                        )
                        window.open(url, '_blank')
                    }}
                >
                    {params.row.page_number}
                </Link>
            ),
            valueGetter: (params: any, row: any) => row.page_number,
        },
        {
            field: 'upload_date_created',
            headerName: 'upload_date_created',
            flex: 1,
            filterable: true,
        },
    ]

    const [rows, setRows] = React.useState<any[]>([])
    // Function to parse filters from URL
    const parseFiltersFromURL = () => {
        const items: any = Array.from(searchParams.entries()).map(([field, value]) => ({
            field: field,
            operator: 'contains',
            operatorValue: 'contains',
            value: value,
        }))
        setFilterModel({ items: items || [] }) // Default to an empty array if items is undefined
    }

    // Function to update the URL when filters change
    const updateURLWithFilters = (newFilterModel: GridFilterModel) => {
        const newParams = new URLSearchParams()
        newFilterModel.items.forEach((item) => {
            if (item.value) {
                console.log(item)
                newParams.set(item.field, item.value.toString())
            }
        })
        console.log(newParams)
        setSearchParams(newParams)
    }

    useEffect(() => {
        parseFiltersFromURL() // Parse filters from URL on mount
        async function load() {
            const result = await ApiService.getProjectSearchResults()
            if (result.status === 200) {
                const rowItems = result.data
                setRows(rowItems)
            }
        }

        load()
    }, [])

    const handleFilterChange = (newFilterModel: GridFilterModel) => {
        setFilterModel(newFilterModel)
        updateURLWithFilters(newFilterModel)
    }

    return (
        <Container sx={{ minHeight: 250 }} maxWidth={'xl'}>
            {rows.length === 0 && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                        <CircularProgress />
                    </div>
                </>
            )}
            <Stack spacing={2} mb={2}>
                {rows.length > 0 && (
                    <>
                        <Typography variant={'h2'} mb={2}>
                            Total Results {rows.length}
                        </Typography>
                        <Button
                            variant={'contained'}
                            onClick={async () => {
                                const res = await ApiService.refreshProjectSearchResults()
                                console.log(res.data)
                            }}
                        >
                            Refresh Results
                        </Button>
                    </>
                )}
            </Stack>
            {rows.length > 0 && (
                <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 4 }}>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        density={'compact'}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        filterModel={filterModel} // Corrected filter model type
                        onFilterModelChange={handleFilterChange}
                        pagination
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                            pagination: {
                                paginationModel: { page: 0, pageSize: 25 },
                            },
                        }}
                        columnVisibilityModel={{
                            id: false,
                        }}
                        pageSizeOptions={[25, 100, 250, 500]}
                    />
                </Paper>
            )}
        </Container>
    )
}
