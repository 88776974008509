import * as React from 'react'
import { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import ApiService from '../../../../services/api.service'
import { GridColDef, GridToolbar } from '@mui/x-data-grid'
import { Box, Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useNavigate } from 'react-router-dom'
import { toLocalDate } from '../../../Projects/ToLocalDate'
import { DataGridPro } from '@mui/x-data-grid-pro'

// https://mui.com/material-ui/react-table/#data-table
export default function SearchFilterList() {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState<boolean>(true)

    async function load() {
        const result = await ApiService.getAllSearchGroups()
        if (result.status === 200) {
            const rowItems = result.data
            setRows(rowItems)
        }
        setLoading(false)
    }

    const [rows, setRows] = React.useState<any[]>([])
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.3,
        },
        {
            field: 'terms',
            headerName: 'Terms',
            flex: 0.3,
            renderCell: (params) => {
                return (
                    <>
                        {Array.from(params.row['terms'].map((item: any) => item['name'])).join(
                            ', ',
                        )}
                    </>
                )
            },
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 0.3,
            renderCell: (params) => {
                return <>{params.row['category']}</>
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                const onDelete = (e: any) => {
                    e.stopPropagation() // don't select this row after clicking
                    ApiService.deleteSearchFilterById(params.row.id).then((res) => {
                        console.log(res)
                        load()
                    })
                }
                const onUse = (e: any) => {
                    e.stopPropagation() // don't select this row after clicking
                    navigate(`/search-filters/${params.row.id}/search`)
                    return
                }

                const onEdit = (e: any) => {
                    e.stopPropagation() // don't select this row after clicking
                    navigate(`/search-filters/${params.row.id}/edit`)
                    return
                }
                const onDashboard = (e: any) => {
                    e.stopPropagation() // don't select this row after clicking
                    navigate(`/search-filters/${params.row.id}/dashboard`)
                    return
                }
                let minWidth = 80
                let maxWidth = 120

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Stack direction="row" spacing={1}>
                            <Button
                                variant="outlined"
                                size={'small'}
                                sx={{ minWidth: minWidth, maxWidth: maxWidth }}
                                onClick={onUse}
                                color={'success'}
                            >
                                Search
                            </Button>
                            <Button
                                variant="outlined"
                                size={'small'}
                                sx={{ minWidth: minWidth, maxWidth: maxWidth }}
                                onClick={onEdit}
                                color={'warning'}
                            >
                                Edit
                            </Button>
                            <Button
                                variant="outlined"
                                size={'small'}
                                sx={{ minWidth: minWidth, maxWidth: maxWidth }}
                                onClick={onDashboard}
                            >
                                Dashboard
                            </Button>
                            <Button
                                variant="outlined"
                                size={'small'}
                                sx={{ minWidth: minWidth, maxWidth: maxWidth }}
                                onClick={onDelete}
                                color={'error'}
                            >
                                Delete
                            </Button>
                        </Stack>
                    </Box>
                )
            },
        },
        {
            field: 'date_created',
            headerName: 'Created At',
            flex: 0.3,
            valueFormatter: (params: any) => {
                return toLocalDate(params)
            },
        },
    ]

    useEffect(() => {
        load()
    }, [])
    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    density={'compact'}
                    loading={loading}
                    slots={{ toolbar: GridToolbar }}
                    // sx={{width: '100%', overflow: 'hidden'}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                        },
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 50 },
                        },
                    }}
                    pageSizeOptions={[50, 250, 500]}
                />
            </Paper>
        </>
    )
}
