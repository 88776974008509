import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import DashboardV4 from '../../Dashboard/V3/DashboardV4'

const ELEVATION = 3

function DashboardByIds() {
    const { projectId, searchFilterId } = useParams()

    return (
        <Box>
            <DashboardV4 searchFilterId={searchFilterId} projectId={projectId}></DashboardV4>
        </Box>
    )
}

export default withAuthenticationRequired(DashboardByIds)
