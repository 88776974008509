import http from '../http-common'
import { Config } from '../config/config'
import { AccountUsage } from './api/models/account-usage'
import { AxiosResponse } from 'axios'
import { Projects } from './api/models/projects'
import { Uploads } from './api/models/uploads'
import { Organization } from './api/models/organization'
import { Authority } from './api/models/authority'
import { Account } from './api/models/account'
import { ReviewStatus } from '../components/Dashboard/ReviewStatus'
import { SearchResultsV2 } from './api/models/search_results_v2'
import {
    CreateProjectRequest,
    CreateProjectResponse,
} from '../components/Projects/ProjectsCreate/CreateProjectRequest'
import { SalesforceMyAccount } from './api/models/salesforce-my-account'
class ApiService {
    async register(username: any, password: any) {
        const json = JSON.stringify({
            username: username,
            password: password,
        })
        await http.post('/api/register', json, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return this.login(username, password)
    }

    async login(username: string, password: string) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        const json = JSON.stringify({
            username: username,
            password: password,
        })
        const res = await http.post('/api/login', json, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const token = res.data['access_token']
        if (token !== undefined && token.toString() !== 'undefined') {
            this.setBearerToken(token)
            localStorage.setItem('user', JSON.stringify(res.data))
            const accountRes = await http.get('/api/v1/account')
            localStorage.removeItem('account')
            localStorage.setItem('account', JSON.stringify(accountRes.data))
        }
        return res
    }

    async logout() {
        this.removeLocalStorage()
        http.defaults.headers.common['Authorization'] = ''
    }

    removeLocalStorage() {
        localStorage.removeItem('token')
        localStorage.removeItem('account')
        localStorage.removeItem('user')
        localStorage.removeItem('sidebar_open')
        localStorage.removeItem('has_sf_integration')
    }

    async getAccount(): Promise<AxiosResponse<Account>> {
        const res = await http.get<Account>(`/api/v1/account`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
        if (res.status === 200) {
            localStorage.removeItem('account')
            localStorage.setItem('account', JSON.stringify(res.data))
        }
        return res
    }

    async getSubscriptions(): Promise<AxiosResponse<any>> {
        return await http.get<any>(`/api/v1/billing/subscriptions?return=${window.location.href}`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    async getTiers(): Promise<AxiosResponse<any>> {
        return await http.get<any>(`/api/v1/billing/tiers`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    async getPaymentLink(priceId: any): Promise<AxiosResponse<any>> {
        return await http.get<any>(
            `/api/v1/billing/payment-links/${priceId}?return=${window.location.href}`,
            {
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                },
            },
        )
    }

    getAccountFromStorage(): Account {
        return JSON.parse(localStorage.getItem('account') || '{}') as Account
    }

    async getUserInfo(): Promise<AxiosResponse<any>> {
        localStorage.removeItem('userinfo')

        const res = await http.get<any>(`/auth0/userinfo`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
        if (res.status === 200) {
            localStorage.setItem('userinfo', JSON.stringify(res.data))
        }
        return res
    }

    async getMyOrg(): Promise<AxiosResponse<any>> {
        return await http.get<any>(`/api/v1/organizations/me`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    getAccountFromLocalStorage() {
        return JSON.parse(localStorage.getItem('account') || '{}')
    }

    getUsernameFromLocalStorage() {
        return this.getAccountFromLocalStorage()['username']
    }

    // async userExists(username: any) {
    //     const res = await http.get(`/api/v1/users/${username}/exists`);
    //     return Boolean(res.data['exists']);
    // }

    tokenExpiresIn() {
        const user = JSON.parse(localStorage.getItem('user') || '{}')
        if (Object.keys(user).indexOf('exp') !== -1) {
            const exp = new Date(user['exp'])
            const now = new Date()
            return exp.getTime() - now.getTime() / 1000
        }
        return -1
    }

    isAuthenticated() {
        return this.tokenExpiresIn() > 0
    }

    isInRoles(roles: Authority[]) {
        for (const role of roles) {
            if (role.indexOf(this.getRole()) !== -1) {
                return true
            }
        }
        return false
    }

    getRole() {
        const account = JSON.parse(localStorage.getItem('account') || '{}')
        if (account === null) {
            return Authority.Anonymous
        }
        if (Object.keys(account).indexOf('user') === -1) {
            return Authority.Anonymous
        }
        if (Object.keys(account['user']).indexOf('authority') === -1) {
            return Authority.Anonymous
        }
        switch (account['user']['authority']) {
            case Authority.Admin.toString():
                return Authority.Admin
            case Authority.OrgAdmin.toString():
                return Authority.OrgAdmin
            case Authority.OrgUser.toString():
                return Authority.OrgUser
        }
        return Authority.Anonymous
    }

    saveSearchGroup(name: string, terms: any, category: string = '') {
        return http.post(
            '/api/v1/search-filters',
            JSON.stringify({
                name: name,
                search_filter_category: category,
                terms: terms,
            }),
            {
                headers: {
                    'content-type': 'application/json',
                },
            },
        )
    }

    getAllSearchGroups(query: string = '*') {
        return http.get('/api/v1/search-filters')
    }

    getAllSearchFilterReviews(query: string = '*') {
        return http.get('/api/v1/search-filter-reviews')
    }

    getSfAccount() {
        return http.get('/api/salesforce/userinfo')
    }

    checkSfIntegration() {
        return http.get('/api/salesforce/check')
    }

    getOpportunities() {
        return http.get('/api/salesforce/opportunities')
    }

    getAllOrganizationProcesses() {
        return http.get('/api/v1/organizations/processes')
    }

    getAllSearchResults() {
        return http.get(`/api/v1/search-results`)
    }

    getAllSearchResultsV2(
        projectId: string | undefined = undefined,
        page: number | undefined = 0,
        limit: number | undefined = 500,
    ): Promise<AxiosResponse<SearchResultsV2>> {
        let url = `/api/v2/search-results?page=${page}&limit=${limit}`
        if (projectId !== null && projectId !== undefined) {
            url += `&projectId=${projectId}`
        }
        return http.get<SearchResultsV2>(url)
    }

    getAllSearchResultsV3(
        projectId: string | undefined = undefined,
        page: number | undefined = 0,
        limit: number | undefined = 500,
    ): Promise<AxiosResponse<SearchResultsV2>> {
        let url = `/api/v3/projects/${projectId}/search-results?page=${page}&limit=${limit}`
        if (projectId !== null && projectId !== undefined) {
            url += `&projectId=${projectId}`
        }
        return http.get<SearchResultsV2>(url)
    }

    getSearchResultById(id: string) {
        return http.get(`/api/v1/search-results/${id}`)
    }

    getDashboard(projectId: string | undefined = undefined) {
        if (projectId !== null && projectId !== undefined) {
            return http.get(`/api/v1/dashboard?projectId=${projectId}`)
        }
        return http.get(`/api/v1/dashboard`)
    }

    getDashboardV2() {
        return http.get(`/api/v2/dashboard`)
    }

    getAllUsers() {
        if (this.isInRoles([Authority.Admin])) {
            return this.adminGetUsers()
        }
        return http.get('/api/v1/users')
    }

    getAllProjects() {
        return http.get('/api/v1/projects')
    }

    getAllSearchFilterCategories(query: string = '*') {
        return http.get('/api/v1/search-filters/categories')
    }

    getAllSearchFilters(includes: string[] = []) {
        let url = `/api/v1/search-filters`
        if (this.isInRoles([Authority.Admin])) {
            url = `/api/v1/admin/search-filters`
        }
        if (includes.length > 0) {
            url += `?includes=${includes.join(',')}`
        }
        return http.get(url)
    }

    getProjectById(id: string): Promise<AxiosResponse<any>> {
        return http.get(`/api/v1/projects/${id}`)
    }

    processProjectById(id: string) {
        return http.post(`/api/v1/projects/${id}/process`)
    }

    async getProjects(includes: string[] = []): Promise<AxiosResponse<Projects[]>> {
        let url = `/api/v1/projects`
        if (includes.length > 0) {
            url += `?includes=${includes.join(',')}`
        }
        return await http.get<Projects[]>(url, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    async getOrganizations(): Promise<AxiosResponse<Organization[]>> {
        return await http.get<Organization[]>(`/api/v1/organizations`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    getAllOrganizations(query: string = '*') {
        if (this.isInRoles([Authority.Admin])) {
            return this.adminGetOrganizations()
        }
        return http.get<Organization[]>('/api/v1/organizations')
    }

    async getUploads(): Promise<AxiosResponse<Uploads[]>> {
        return await http.get<Uploads[]>(`/api/v1/uploads`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }
    async getProjectSearchResults(): Promise<AxiosResponse<any[]>> {
        return await http.get<any[]>(`/api/v1/project-search-results`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }
    async refreshProjectSearchResults(): Promise<AxiosResponse<any>> {
        return await http.get<any>(`/api/v1/project-search-results/refresh`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    getSearchFilterById(id: string) {
        return http.get(`/api/v1/search-filters/${id}`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    deleteSearchFilterById(id: string) {
        return http.delete(`/api/v1/search-filters/${id}`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    deleteUserById(id: string) {
        return http.delete(`/api/v1/users/${id}`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    deleteOrganizationById(id: string) {
        return http.delete(`/api/v1/organizations/${id}`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    async searchDocuments(id: string = '*') {
        return await http.get(`/api/v1/search-filters/${id}/search`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    async getAccountUsage(): Promise<AxiosResponse<AccountUsage>> {
        return await http.get<AccountUsage>(`/api/v1/account/usage`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    async processFiles() {
        return await http.post(
            `/api/v1/files/process`,
            {},
            {
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                },
            },
        )
    }

    openFileUrl(filePath: string, page: string | undefined = undefined) {
        if (page === null || page === undefined) {
            return `${Config.ApiUrl}/api/v1/files/view?path=${encodeURI(filePath)}`
        }
        return `${Config.ApiUrl}/api/v1/files/view?path=${encodeURI(filePath)}&page=${page}`
    }

    viewUploadUrl(id: string, page: string | undefined = undefined) {
        if (page === null || page === undefined) {
            return `${Config.ApiUrl}/api/v1/uploads/${id}/view`
        }
        return `${Config.ApiUrl}/api/v1/uploads/${id}/view?page=${page}`
    }

    viewSearchResults(projectId: string, searchFilterId: string | undefined = undefined) {
        return http.post(
            `${Config.ApiUrl}/api/v3/dashboard/projects/${projectId}/search-filters/${searchFilterId}`,
        )
    }

    viewSearchResultUrl(id: string) {
        return `${Config.ApiUrl}/api/v1/search-results/${id}/view`
    }

    saveProject(name: string) {
        return http.post(
            '/api/v1/projects',
            JSON.stringify({
                name: name,
            }),
            {
                headers: {
                    'content-type': 'application/json',
                },
            },
        )
    }

    reviewSearchResult(id: any, status: string) {
        return http.post(
            `/api/v1/search-results/${id}/review?status=${status}`,
            JSON.stringify({}),
            {
                headers: {
                    'content-type': 'application/json',
                },
            },
        )
    }

    buildReviewSearchResultsUrl(ids: any[]) {
        return http.post(`/api/v1/search-results/view`, JSON.stringify(ids), {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    reviewProjectSearchFilter(
        projectId: any,
        searchFilterId: string,
        status: ReviewStatus,
        comment: any | undefined = undefined,
        toSalesforce: boolean | undefined = false,
    ) {
        return http.post(
            `/api/v1/projects/${projectId}/search-filters/${searchFilterId}/reviews?status=${status.toString()}&sf=${toSalesforce}`,
            JSON.stringify({
                comment: comment,
            }),
            {
                headers: {
                    'content-type': 'application/json',
                },
            },
        )
    }

    getProjectSearchFilterReviews(projectId: any, searchFilterId: string) {
        return http.get(`/api/v1/${projectId}/search-filters/${searchFilterId}/reviews`, {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    reviewSearchResultsBulk(ids: any[], status: string) {
        return http.post(
            `/api/v1/search-results/bulk-review?status=${status}`,
            JSON.stringify(ids),
            {
                headers: {
                    'content-type': 'application/json',
                },
            },
        )
    }

    deleteUploadById(id: string) {
        return http.delete(`/api/v1/uploads/${id}`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    deleteProjectById(id: string) {
        return http.delete(`/api/v1/projects/${id}`, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    updateProject({
        id,
        name,
        address = {},
        bidDate = null,
        alternateName = null,
        dollarAmount = null,
    }: {
        id: string
        name?: string | null
        address?: any | null
        bidDate?: string | null
        alternateName?: string | null
        dollarAmount?: any | null
    }) {
        return http.put(
            `/api/v1/projects/${id}`,
            JSON.stringify({
                name: name,
                address: address,
                bid_date: bidDate,
                dollar_amount: dollarAmount,
                alternate_name: alternateName,
            }),
            {
                headers: {
                    'content-type': 'application/json',
                },
            },
        )
    }

    addProjectContact(id: string, body: any) {
        return http.post(`/api/v1/projects/${id}/contacts`, JSON.stringify(body), {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    getProjectContacts(id: string) {
        return http.get(`/api/v1/projects/${id}/contacts`)
    }

    isSidebarOpen() {
        return (localStorage.getItem('sidebar_open') || '') === 'true'
    }

    isAnalyticsOpen() {
        return (localStorage.getItem('sidebar_analytics_open') || '') === 'true'
    }

    isAdminOpen() {
        return (localStorage.getItem('sidebar_admin_open') || '') === 'true'
    }

    isProjectsOpen() {
        return (localStorage.getItem('sidebar_projects_open') || '') === 'true'
    }

    setSidebarOpen(value: boolean) {
        localStorage.setItem('sidebar_open', value.toString())
    }

    isDarkMode() {
        return (localStorage.getItem('dark_mode') || '') === 'true'
    }

    setIsDarkMode(value: boolean) {
        localStorage.setItem('dark_mode', value.toString())
    }

    setAdminOpen(value: boolean) {
        localStorage.setItem('sidebar_admin_open', value.toString())
    }

    setProjectsOpen(value: boolean) {
        localStorage.setItem('sidebar_projects_open', value.toString())
    }

    setAnalyticsOpen(value: boolean) {
        localStorage.setItem('sidebar_analytics_open', value.toString())
    }

    addUserToOrganization(id: string, body: any) {
        return http.post(`/api/v1/organizations/${id}/users`, JSON.stringify(body), {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    addUserToOrganizationById(id: string, userId: any) {
        return http.post(`/api/v1/organizations/${id}/users/${userId}`, JSON.stringify({}), {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    createOrganization(body: any) {
        return http.post(`/api/v1/organizations`, JSON.stringify(body), {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    loginUrl() {
        return `http://localhost:5001/auth0/login?r=${window.location.href}`
    }

    saveToken(token: string | undefined) {
        localStorage.setItem('token', token || '')
    }

    hasToken() {
        return (localStorage.getItem('token') || '') !== ''
    }

    async get(url: string) {
        return await http.get(url, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    async post(url: string) {
        return await http.post(url, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
    }

    async submitContactForm(name: any, email: any, messageText: any) {
        return http.post(
            `/api/v1/public/contact`,
            JSON.stringify({
                name: name,
                email: email,
                message: messageText,
            }),
            {
                headers: {
                    'content-type': 'application/json',
                },
            },
        )
    }

    async submitEmailSubscribe(email: any) {
        return http.post(
            `/api/v1/public/email-subscribe`,
            JSON.stringify({
                email: email,
            }),
            {
                headers: {
                    'content-type': 'application/json',
                },
            },
        )
    }

    async adminGetProjects() {
        return http.get(`/api/v1/admin/projects`, {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    async adminGetUsers() {
        return http.get(`/api/v1/admin/users`, {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    async adminGetOrganizations() {
        return http.get(`/api/v1/admin/organizations`, {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    async adminGetSearchFilters() {
        return http.get(`/api/v1/admin/search-filters`, {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    async uploadsToZip(ids: any[]) {
        return http.post(`/api/v1/uploads/download`, JSON.stringify(ids), {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    async uploadsToZipOpen(ids: any[]) {
        return this.uploadsToZip(ids).then((res) => {
            window.open(res.data.url, '_blank')
        })
    }

    async loginWithSalesforce(param: {
        sub?: string | undefined
        redirect_uri?: string | undefined
        prompt?: string | undefined
    }) {
        let accountFromStorage = this.getAccountFromStorage()
        let url = `${Config.ApiUrl}/api/salesforce/login?prompt=${param?.prompt || 'login'}&sub=${param?.sub || accountFromStorage.user.id}&redirect_uri=${param?.redirect_uri || window.location.origin + window.location.pathname}`
        console.log(url)
        window.location.href = url
    }

    async getAnalytics() {
        return this.get(`${Config.ApiUrl}/api/v1/analytics`)
    }

    async addUserToOrg(props: {
        organizationId?: string
        firstName?: string
        lastName?: string
        email?: string
        password?: string
    }) {
        return http.post(
            `/api/v1/organizations/${props.organizationId}/users`,
            JSON.stringify({
                first_name: props.firstName,
                last_name: props.lastName,
                email: props.email,
                password: props.password,
            }),
            {
                headers: {
                    'content-type': 'application/json',
                },
            },
        )
    }

    private setBearerToken(token: any) {
        localStorage.setItem('token', token)
        http.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
            'Content-Type': `application/json`,
        }
    }

    resetPasswordForUser(user_id: any) {
        return http.post(`/api/v1/admin/users/${user_id}/reset-password`, {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    resetMyPassword() {
        return http.post(`/api/v1/account/reset-password`, {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    assignRole(id: any, role: any) {
        return http.post(`/api/v1/users/${id}/assign-role/${role}`, {
            headers: {
                'content-type': 'application/json',
            },
        })
    }

    async getOrgByName(name: any) {
        return this.get(`${Config.ApiUrl}/api/v1/organizations/by-name?name=${name}`)
    }

    async getDashboardV3() {
        return this.get(`${Config.ApiUrl}/api/v3/dashboard`)
    }

    async getDashboardV4(
        projectId: any | null | undefined = undefined,
        searchFilterId: any | null | undefined = undefined,
    ) {
        if (searchFilterId && projectId) {
            return this.get(
                `${Config.ApiUrl}/api/v4/dashboard?projectId=${projectId}?searchFilterId=${searchFilterId}`,
            )
        }
        if (projectId) {
            return this.get(`${Config.ApiUrl}/api/v4/dashboard?projectId=${projectId}`)
        }
        if (searchFilterId) {
            return this.get(`${Config.ApiUrl}/api/v4/dashboard?searchFilterId=${searchFilterId}`)
        }

        return this.get(`${Config.ApiUrl}/api/v4/dashboard`)
    }

    async createUser(email: any, firstName: any, lastName: any) {
        return await http.post(
            '/api/v1/users',
            JSON.stringify({
                email: email,
                firstName: firstName,
                lastName: lastName,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
    }

    async createProject(
        project: CreateProjectRequest,
    ): Promise<AxiosResponse<CreateProjectResponse>> {
        return await http.post('/api/v1/projects', JSON.stringify(project), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    getSalesforceObjectTypes() {
        return this.get(`${Config.ApiUrl}/api/salesforce/object-types`)
    }
    getSalesforceObjectTypeMappings() {
        return this.get(`${Config.ApiUrl}/api/salesforce/object-type-mappings`)
    }
    processAllSearchFilters() {
        return this.post(`${Config.ApiUrl}/process-all-filters`)
    }

    getSalesforceMyAccount(): Promise<AxiosResponse<SalesforceMyAccount>> {
        return http.get<SalesforceMyAccount>(`${Config.ApiUrl}/api/salesforce/my-account`)
    }
    updateSalesforceObjectTypes(body: any) {
        const json = JSON.stringify(body)
        return http.post(`/api/salesforce/object-type-mappings`, json, {
            headers: {
                'content-type': 'application/json',
            },
        })
    }
    getSalesforceObjects(objectType: any, searchString: any | null | undefined = null) {
        return http.get(`/api/salesforce/objects`, {
            params: {
                type: objectType,
                search: searchString,
            },
        })
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ApiService()
