import * as React from 'react'
import { useEffect } from 'react'
import {
    DataGridPro,
    GridColDef,
    GridFooter,
    GridFooterContainer,
    GridRenderCellParams,
    GridToolbar,
} from '@mui/x-data-grid-pro'
import ApiService from '../../../services/api.service'
import { Box, Button, CircularProgress } from '@mui/material'
import { GridRowParams } from '@mui/x-data-grid/models/params'
import { useParams } from 'react-router-dom'
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel'
import ButtonGroup from '@mui/material/ButtonGroup'
import DownloadIcon from '@mui/icons-material/Download'
import { toLocalDate } from '../../Projects/ToLocalDate'

function renderDeleteProjectById(params: GridRenderCellParams<any, string>) {
    if (params.value === undefined || params.value.toString() === '') {
        return <></>
    }

    function update(ev: any, id: string) {
        ApiService.deleteUploadById(id)
    }

    return (
        <Button
            key={`${params.value || new Date().toString()}-view-button`}
            size="small"
            color={'error'}
            variant={'contained'}
            onClick={(ev: any) => {
                update(ev, params.value || '')
            }}
        >
            Delete Upload
        </Button>
    )
}

function renderViewUploadById(params: GridRenderCellParams<any, string>) {
    if (params.value === undefined || params.value.toString() === '') {
        return <></>
    }
    const onViewDoc = () => {
        const url = ApiService.viewUploadUrl(params.row['id'])
        window.open(url, '_blank')
        return
    }

    return (
        <Button
            key={`${params.value || new Date().toString()}-view-button`}
            size="small"
            color={'primary'}
            variant={'contained'}
            onClick={(ev: any) => {
                ev.stopPropagation()
                onViewDoc()
            }}
        >
            View
        </Button>
    )
}

export default function ListProjectUploads(
    props: {
        projectId: string | undefined
        uploads: any[] | undefined
        project: any | undefined
    } = { projectId: undefined, uploads: undefined, project: undefined },
) {
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'sizeInMb',
            headerName: 'Size (MB)',
            flex: 0.5,
            renderCell: (params) => {
                return <Box>{params.value && <>{params.value.toFixed(2)} MB</>}</Box>
            },
        },

        {
            field: 'viewById',
            headerName: 'View',
            flex: 0.75,
            renderCell: renderViewUploadById,
        },
        {
            field: 'createdByUserEmail',
            headerName: 'Uploaded By',
            flex: 1,
        },
        {
            field: 'dateCreated',
            headerName: 'Created At',
            flex: 1,
            valueFormatter: (params: any) => {
                return toLocalDate(params)
            },
        },
        {
            field: 'id',
            headerName: 'Delete',
            flex: 1,
            renderCell: renderDeleteProjectById,
        },
    ]

    const [rows, setRows] = React.useState<any[]>([])
    const [selectedRows, setSelectedRows] = React.useState<any[]>([])
    const [cols] = React.useState<GridColDef[]>(columns)
    const { id } = useParams()
    const [projectId, setProjectId] = React.useState<string | undefined>(id || props.projectId)
    const [isDownloadingZipFiles, setIsDownloadingZipFiles] = React.useState<boolean>(false)

    async function downloadPdfZipFiles(ev: any) {
        ev.preventDefault()
        setIsDownloadingZipFiles(true)
        return ApiService.uploadsToZipOpen(selectedRows).then(() => {
            setIsDownloadingZipFiles(false)
        })
    }

    function CustomFooter() {
        return (
            <GridFooterContainer>
                <Box
                    sx={{
                        // maxWidth: 800,
                        flex: 1,
                        overflow: 'hidden',
                        // overflow: 'hidden',
                        // display: 'flex',
                        // '& > *': {
                        //     m: 1,
                        // },
                    }}
                >
                    {selectedRows.length > 0 && (
                        <>
                            <ButtonGroup
                                orientation="horizontal"
                                aria-label="Vertical button group"
                                variant="text"
                            >
                                <Button
                                    key={`download-button`}
                                    size="small"
                                    color={'info'}
                                    variant={'contained'}
                                    startIcon={
                                        isDownloadingZipFiles ? (
                                            <CircularProgress size={12} />
                                        ) : (
                                            <DownloadIcon />
                                        )
                                    }
                                    sx={{ mr: 2 }}
                                    disabled={isDownloadingZipFiles}
                                    onClick={downloadPdfZipFiles}
                                >
                                    {isDownloadingZipFiles
                                        ? `Downloading ${selectedRows.length} files`
                                        : `Download zip (${selectedRows.length} files)`}
                                </Button>
                            </ButtonGroup>
                        </>
                    )}
                </Box>
                <GridFooter
                    sx={{
                        border: 'none', // To delete double border.
                    }}
                ></GridFooter>
            </GridFooterContainer>
        )
    }

    useEffect(() => {
        async function load() {
            if (projectId === undefined) {
                setProjectId(props.projectId)
            }
            if (projectId === undefined) {
                setProjectId(id)
            }
            if (projectId !== undefined) {
                let rowData = []
                if (props.uploads === undefined || props.uploads?.length === 0) {
                    const result = await ApiService.getProjectById(projectId)
                    rowData = result.data['uploads']
                    console.log(rowData)
                } else {
                    rowData = props.uploads
                }
                const rowItems = rowData.map((item: any) => {
                    return {
                        id: item['id'],
                        name: item['name'],
                        sizeInMb: item['size_in_mb'],
                        viewById: item['id'],
                        createdByUserEmail: item['created_by_user_email'],
                        dateCreated: item['date_created'],
                    }
                })
                setRows(rowItems)
            }
        }

        load()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onSelectChange(rowSelectionModel: GridRowSelectionModel, details: any) {
        console.log(details.api)
        const srs = details.api.getSelectedRows()
        const keys = Array.from(srs.keys())
        console.log(keys)
        // const selected: string[] = [];
        setSelectedRows(keys)
        console.log(selectedRows)
        // for (const row of rowSelectionModel) {
        //     selected.push(row.toString())
        // }
        //
        // setSelectedRows(selected);
        // console.log(selectedRows);
    }

    return (
        <Box
            sx={{
                flex: 1,
                overflow: 'hidden',
                maxWidth: '90vw',
            }}
        >
            {/*{props.project && <JsonData data={props.project}></JsonData>}*/}
            {rows.length > 0 ? (
                <DataGridPro
                    isRowSelectable={(params: GridRowParams) => true}
                    checkboxSelection
                    loading={rows.length === 0}
                    rowSelection={true}
                    rows={rows}
                    onRowSelectionModelChange={onSelectChange}
                    columns={cols}
                    density={'compact'}
                    slots={{
                        toolbar: GridToolbar,
                        footer: CustomFooter,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                        footer: {},
                    }}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 100, 250, 500]}
                />
            ) : (
                <>No Data</>
            )}
        </Box>
    )
}
